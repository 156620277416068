import { IActionContext, IRequestContext } from '@msdyn365-commerce/core';
import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import {
    getSelectedVariant,
    SelectedVariantInput,
    createInventoryAvailabilitySearchCriteria
} from '@msdyn365-commerce-modules/retail-actions';
import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductWarehouseInventoryInformation, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

export async function _getProductInventory(
    product: FullProduct,
    ctx: IActionContext
): Promise<ProductWarehouseInventoryInformation | undefined> {
    const searchCriteria = createInventoryAvailabilitySearchCriteria(ctx, [product.ProductDetails.RecordId], true);
    const productAvailabilitiesResponse = await getEstimatedAvailabilityAsync({ callerContext: ctx }, searchCriteria);
    return productAvailabilitiesResponse;
}

export async function _getProductDetails(
    product: SimpleProduct,
    channelId: number,
    actionContext: IActionContext,
    requestContext: IRequestContext
): Promise<SimpleProduct | null> {
    const selectedProductPromise = getSelectedVariant(
        new SelectedVariantInput(
            product!.MasterProductId ? product!.MasterProductId : product!.RecordId,
            channelId,
            undefined,
            undefined,
            requestContext
        ),
        actionContext
    );

    const selectedProduct: SimpleProduct | null = await selectedProductPromise;
    return Promise.resolve(selectedProduct);
}

export async function getProductDetails(
    products: SimpleProduct[],
    channelId: number,
    context: IActionContext
): Promise<IHSOProductInventory[]> {
    let i = 0;
    let productsInventory: IHSOProductInventory[] = [];
    for (i = 0; i < products.length; i++) {
        let element: IHSOProductInventory = { productDetails: null, productsInventory: null };
        if (products[i] !== undefined) {
            const simpleProduct = await _getProductDetails(products[i], channelId, context, context.requestContext);
            const productInventoryInformation = await _getProductInventory({ ProductDetails: products[i] }, context);
            if (simpleProduct) {
                element.productDetails = simpleProduct;
            }
            if (productInventoryInformation) {
                element.productsInventory = productInventoryInformation;
            }
            productsInventory.push(element);
        }
    }
    return productsInventory;
}

export interface IHSOProductInventory {
    productDetails: SimpleProduct | null;
    productsInventory: ProductWarehouseInventoryInformation | null;
}
